export const links = {
  OPEN_TOFU: {
    ROOT: 'https://opentofu.org/',
    COMMANDS: {
      TEST: 'https://opentofu.org/docs/cli/commands/test/'
    },
    DOCS: 'https://docs.env0.com/docs/modules-continuous-integration-testing'
  },
  CONTACT_US: 'https://www.env0.com/contact',
  SALES_EMAIL: 'sales@env0.com',
  legal: {
    TERMS: 'https://www.env0.com/terms-and-conditions',
    PRIVACY: 'https://www.env0.com/envzero-privacy-policy'
  },
  DEMO: {
    SCHEDULE_DEMO: 'https://meetings.env0.com/meetings/env0/bookdemo'
  },
  docs: {
    ROOT: 'https://docs.env0.com',
    DEPLOYMENT_CREDENTIALS: {
      CONNECT_CLOUD_ACCOUNT: 'https://docs.env0.com/docs/connect-your-cloud-account',
      CREATE_AWS_ROLE: 'https://docs.env0.com/docs/connect-your-cloud-account#create-an-aws-iam-role',
      AWS_ACCESS_KEY_DOCS:
        'https://docs.aws.amazon.com/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys',
      AWS_OIDC_DOCS: 'https://docs.env0.com/docs/oidc-with-aws',
      GCP_SERVICE_ACCOUNT_DOCS:
        'https://cloud.google.com/iam/docs/creating-managing-service-account-keys#creating_service_account_keys',
      GCP_OIDC_DOCS: 'https://docs.env0.com/docs/oidc-with-google-cloud-platform',
      AZURE_OIDC_DOCS: 'https://docs.env0.com/docs/oidc-with-azure',
      AZURE_SERVICE_PRINCIPAL_DOCS: 'https://docs.env0.com/docs/connect-your-cloud-account#azure',
      K8S: 'https://docs.env0.com/docs/connect-your-cloud-account#kubernetes',
      K8S_KUBECONFIG_AUTH: 'https://docs.env0.com/docs/connect-your-cloud-account#kubeconfig',
      K8S_EKS_AUTH: 'https://docs.env0.com/docs/connect-your-cloud-account#aws-eks',
      K8S_AKS_AUTH: 'https://docs.env0.com/docs/connect-your-cloud-account#azure-aks',
      K8S_GKE_AUTH: 'https://docs.env0.com/docs/connect-your-cloud-account#gcp-gke',
      VAULT_OIDC_DOCS: 'https://docs.env0.com/docs/oidc-with-vault'
    },
    ENABLING_COST_MONITORING: {
      ROOT: 'https://docs.env0.com/docs/cost-monitoring',
      CREATE_AWS_IAM_POLICY_ROLE: 'https://docs.env0.com/docs/aws-costs',
      CREATE_GOOGLE_CREDENTIALS: 'https://docs.env0.com/docs/gcp-costs',
      CREATE_AZURE_CREDENTIALS: 'https://docs.env0.com/docs/setup-azure-costs',
      EXPORT_GOOGLE_BILLING_TO_BIGQUERY:
        'https://docs.env0.com/docs/gcp-costs#section-export-project-billing-data-into-bigquery',
      AWS_GRANULARITY: 'https://docs.env0.com/docs/aws-costs#enable-hourly-and-resource-level-data',
      BUDGET_NOTIFICATIONS: 'https://docs.env0.com/docs/budget-notifications'
    },
    PROJECTS: {
      ROOT: 'https://docs.env0.com/docs/projects'
    },
    DASHBOARDS: {
      ROOT: 'https://docs.env0.com/docs/dashboards'
    },
    POLICIES: {
      ROOT: 'https://docs.env0.com/docs/policies',
      ENVIRONMENT_LIMITS: 'https://docs.env0.com/docs/environment-limits',
      SKIP_REDUNDANT_DEPLOYMENTS: 'https://docs.env0.com/docs/skip-redundant-deployments',
      SKIP_PR_PLAN_ON_MERGE_COMMITS: 'https://docs.env0.com/docs/skip-pr-plan-on-merge-commits',
      RUN_PR_COMMENTS_COMMANDS_USING_ALIAS: 'https://docs.env0.com/docs/plan-and-apply-from-pr-comments#configuration',
      RUN_PR_COMMENTS_COMMANDS_MAP_VCS_PROVIDER_USER:
        'https://docs.env0.com/docs/plan-and-apply-from-pr-comments#map-vcs-provider-user',
      RUN_PR_COMMENT_COMMANDS_ENFORCE_PR_COMMENTER_PERMISSIONS:
        'https://docs.env0.com/docs/plan-and-apply-from-pr-comments#enforce-pr-commenter-permissions-on-env0',
      COST_ESTIMATION: 'https://docs.env0.com/docs/cost-estimation',
      ENVIRONMENT_OUTPUTS: 'https://docs.env0.com/docs/environment-outputs',
      OIDC_INTEGRATIONS: 'https://docs.env0.com/docs/oidc-integrations',
      APPROVAL_POLICIES: 'https://docs.env0.com/docs/approval-policies',
      ALLOW_ENV0_SECRETS: 'https://docs.env0.com/docs/allow-env0-secrets'
    },
    ENVIRONMENTS: {
      ROOT: 'https://docs.env0.com/docs/environments',
      WORKFLOW_TRIGGERS: 'https://docs.env0.com/docs/workflow-triggers',
      CREATE_NEW_ENVIRONMENT: 'https://docs.env0.com/docs/setting-up-a-new-environment',
      CREATE_NEW_WORKFLOW: 'https://docs.env0.com/docs/create-a-new-workflow',
      TARGETED_DEPLOYMENTS: 'https://docs.env0.com/docs/targeted-deployments',
      CONFIGURATION_SETS: 'https://docs.env0.com/docs/variable-sets',
      MOVE_ENVIRONMENT: 'https://docs.env0.com/docs/move-environment'
    },
    TEMPLATES: {
      ROOT: 'https://docs.env0.com/docs/templates',
      MANAGE_GIT_CONNECTIVITY: 'https://docs.env0.com/docs/templates#section-manage-git-connectivity',
      CUSTOM_FLOWS: 'https://docs.env0.com/docs/custom-flows',
      PROJECT_LEVEL_CUSTOM_FLOWS: 'https://docs.env0.com/docs/project-level-custom-flow',
      TOKENS: 'https://docs.env0.com/docs/templates#section--tokens-http-s-',
      GITHUB_INTEGRATION: 'https://docs.env0.com/docs/github-templates',
      BITBUCKET_SERVER_INTEGRATION: 'https://docs.env0.com/docs/bitbucket-server-integration',
      GITLAB_EE_INTEGRATION: 'https://docs.env0.com/docs/gitlab-enterprise-integration',
      GITHUB_ENTERPRISE_INTEGRATION: 'https://docs.env0.com/docs/github-enterprise-integration',
      AZURE_DEVOPS: 'https://docs.env0.com/docs/azure-devops-integration'
    },
    MODULES: {
      ROOT: 'https://docs.env0.com/docs/modules',
      TESTING: 'https://docs.env0.com/docs/modules-continuous-integration-testing'
    },
    PROVIDERS: {
      ROOT: 'https://docs.env0.com/docs/providers',
      CREATE_PROVIDER: 'https://docs.env0.com/docs/providers#creating-a-provider'
    },
    VARIABLES: {
      VARIABLES_AND_SCOPES: 'https://docs.env0.com/docs/variables#section-variables-and-scopes-in-env0',
      ENVIRONMENT_OUTPUTS: 'https://docs.env0.com/docs/environment-outputs#environment-outputs-activation'
    },
    USER: {
      USER_MANAGEMENT: 'https://docs.env0.com/docs/user-management',
      PROJECT_ROLES: 'https://docs.env0.com/docs/user-management#manage-users-of-a-project',
      CUSTOM_ROLE_PERMISSIONS: 'https://docs.env0.com/docs/rbac#custom-role-permissions'
    },
    CONFIGURATION: {
      VARIABLES: 'https://www.terraform.io/docs/configuration/variables.html'
    },
    SCHEDULING: {
      ROOT: 'https://docs.env0.com/docs/scheduling'
    },
    ENVIRONMENT_DISCOVERY: {
      ROOT: 'https://docs.env0.com/docs/environment-discovery'
    },
    DRIFT_DETECTION: {
      ROOT: 'https://docs.env0.com/docs/drift-detection'
    },
    TEAMS: {
      ROOT: 'https://docs.env0.com/docs/teams'
    },
    CONTROL: {
      TERRAFORM_VERSION: 'https://docs.env0.com/docs/additional-controls#specify-terraform-version',
      SKIP_GET_WORKING_DIRECTORY: 'https://docs.env0.com/docs/additional-controls#skip-get-working-directory'
    },
    NOTIFICATIONS: {
      SLACK: 'https://docs.env0.com/docs/notifications'
    },
    AGENTS: {
      ROOT: 'https://docs.env0.com/docs/self-hosted-kubernetes-agent',
      MONITORING: 'https://docs.env0.com/docs/self-hosted-agents-monitoring'
    },
    CONTINUOUS_DEPLOYMENT: {
      CUSTOM_GLOB: 'https://docs.env0.com/docs/continuous-deployment'
    },
    CHANGE_LOG: {
      INDEX: 'https://docs.env0.com/changelog',
      RSS_FEED: 'https://docs.env0.com/changelog.rss'
    },
    TASK_DEPLOYMENT: 'https://docs.env0.com/docs/ad-hoc-tasks',
    REMOTE_BACKEND: {
      ROOT: 'https://docs.env0.com/docs/remote-backend',
      LOGIN: 'https://docs.env0.com/docs/login',
      REMOTE_APPLY: 'https://docs.env0.com/docs/remote-apply',
      STATE_MIGRATION: 'https://docs.env0.com/docs/state-migration'
    },
    AUDIT_LOGS: {
      ROOT: 'https://docs.env0.com/docs/audit-logs'
    },
    RBAC: {
      ROOT: 'https://docs.env0.com/docs/rbac'
    },
    ENVIRONMENT_LOCKING: {
      ROOT: 'https://docs.env0.com/docs/environment-locking'
    },
    BILLING: {
      ROOT: 'https://docs.env0.com/docs/tiers'
    },
    BULK_OPERATIONS: {
      ROOT: 'https://docs.env0.com/docs/bulk-operations'
    },
    ENVIRONMENT_IMPORT: {
      DOWNLOAD: 'https://docs.env0.com/docs/environment-import-1'
    },
    ANSIBLE_DOCS: {
      ANSIBLE_STEPS: 'https://docs.env0.com/docs/ansible#execution-steps'
    }
  },
  CONTACT: {
    MAIL_FOR_TEAMS: 'mailto:team@env0.com?subject=Interested in Adding Teams To My Account'
  },
  AWS: {
    EXTERNAL_ID_DOCS: 'https://docs.aws.amazon.com/IAM/latest/UserGuide/reference_iam-quotas.html'
  },
  GITHUB: {
    env0: {
      HTTPS_BLUEPRINTS: 'https://github.com/env0/blueprints.git',
      SSH_BLUEPRINTS: 'git@github.com:env0/blueprints.git'
    }
  },
  BITBUCKET_SERVER: {
    WEBHOOK_SECRET_DOCS:
      'https://confluence.atlassian.com/bitbucketserver/manage-webhooks-938025878.html#Managewebhooks-webhooksecretsWebhooksecrets'
  },
  GITLAB_EE: {
    WEBHOOK_SECRET_DOCS: 'https://docs.gitlab.com/ee/user/project/integrations/webhooks.html#secret-token'
  },
  GITHUB_ENTERPRISE: {
    WEBHOOK_SECRET_DOCS: 'https://docs.github.com/en/developers/webhooks-and-events/webhooks/securing-your-webhooks'
  },
  CLI: {
    NPM: 'https://www.npmjs.com/package/@env0/cli'
  },
  TERRAFORM_DOCS: {
    BACKEND_INITIALIZATION: 'https://www.terraform.io/cli/commands/init#backend-initialization'
  },
  PULUMI_DOCS: {
    ENVIRONMENT_DEPLOYMENT: 'https://docs.env0.com/docs/pulumi#environment-deployment'
  },
  TERRAGRUNT_DOCS: {
    RUN_ALL:
      'https://terragrunt.gruntwork.io/docs/features/execute-terraform-commands-on-multiple-modules-at-once/#the-run-all-command',
    SUPPORTED_VERSIONS:
      'https://terragrunt.gruntwork.io/docs/getting-started/supported-versions/#supported-opentofu-versions'
  },
  K8S_DOCS: {
    ENVIRONMENT_DEPLOYMENT: 'https://docs.env0.com/docs/k8s#environment-deployment',
    K8S_NAMESPACE: 'https://kubernetes.io/docs/tasks/administer-cluster/namespaces-walkthrough/#create-new-namespaces'
  },
  HELM_DOCS: {
    ENVIRONMENT_DEPLOYMENT: 'https://docs.env0.com/docs/helm#environment-deployment'
  },
  BILLING: {
    CONTACT_US: 'https://www.env0.com/request-meeting',
    TIERS: 'https://docs.env0.com/docs/tiers',
    AWS_MARKETPLACE: 'https://aws.amazon.com/marketplace/seller-profile?id=55540b66-733b-4d1f-b4fd-84a376407155',
    AZURE_MARKET_PLACE: 'https://azuremarketplace.microsoft.com/en-us/marketplace/apps?search=env0&page=1'
  },
  BUDGETS: {
    PROJECT_BUDGET: 'https://docs.env0.com/docs/budgets#project-budget'
  },
  INTEGRATIONS: {
    PLUGINS: 'https://docs.env0.com/docs/plugins'
  },
  CLOUD: {
    INFO: {
      RESOURCES_TABLE: 'https://docs.env0.com/docs/cloud-compass#assess-resource-breakdown',
      SETTINGS: 'https://docs.env0.com/docs/cloud-compass#settings',
      TREND: 'https://docs.env0.com/docs/cloud-compass#view-iac-coverage-trends',
      CURRENT: 'https://docs.env0.com/docs/cloud-compass#resource-management-type-breakdown'
    },
    REQUIREMENTS: {
      CLOUD_TRAIL: 'https://docs.env0.com/docs/configure-cloud-accounts#ensure-active-cloud-trail',
      S3_PERMISSION: 'https://docs.env0.com/docs/configure-cloud-accounts#grant-bucket-permissions',
      KMS_PERMISSION: 'https://docs.env0.com/docs/configure-cloud-accounts#grant-decrypt-permission'
    }
  }
};
