import pick from 'lodash/pick';
import type {
  ConfigurationProperty,
  CreateEnvironmentArgs,
  DeployEnvironmentWithoutTemplateArgs,
  DeploymentLog,
  Environment,
  RunTaskPayload
} from 'types/api.types';
import type { HttpClient } from 'services/api-client/client';
import { getResponseErrorData, returnData } from 'services/api-client/client';
import type { EnvironmentApi } from '@env0/environment-service/api';
import type { AxiosRequestConfig, AxiosResponse, RawAxiosResponseHeaders } from 'axios';
import type { RunEnvironmentArgs } from 'utils/environment.utils';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import type { RolesApi } from '@env0/role-service/api';

export const invalidWorkspaceNameMessageId = 'environment.errors.workspace-name-invalid';
export const workspaceNameTooLongMessageId = 'environment.errors.workspace-name-too-long';

export const DEFAULT_PAGE_SIZE_LIMIT = 100;

export type DeploymentsFilterOptions = {
  fromDate?: string;
  toDate?: string;
  deploymentTypes?: EnvironmentApi.DeploymentType[];
};

const workspaceNameErrorsMapper = {
  'Workspace name already being used': 'environment.errors.workspace-exists',
  'Workspace name contains invalid character': invalidWorkspaceNameMessageId,
  'Workspace name is too long': workspaceNameTooLongMessageId
} as const;

export const getWorkspaceNameErrorMessageId = (error: any) => {
  const errorMessage = getResponseErrorData(error);
  return workspaceNameErrorsMapper[errorMessage as keyof typeof workspaceNameErrorsMapper];
};

const changedConfigurationToPayload = (source: ConfigurationProperty): EnvironmentApi.ConfigurationPropertyChange => {
  class ConfigurationChangePayloadRequest
    implements Required<Omit<EnvironmentApi.ConfigurationPropertyChange, 'projectId' | 'isOutput'>>
  {
    id = null!;
    description = null!;
    isSensitive = null!;
    isReadonly = null!;
    isRequired = null!;
    regex = null!;
    name = null!;
    schema = null!;
    scopeId = null!;
    toDelete = null!;
    type = null!;
    value = null!;
  }

  const configurationChangePropList = Object.keys(new ConfigurationChangePayloadRequest());
  return pick(source, configurationChangePropList) as EnvironmentApi.ConfigurationPropertyChange;
};

type DeployToEnvironmentArgs = {
  environmentId: string;
  blueprintId: string;
  blueprintRevision?: string;
  comment?: string;
  configurationChanges?: ConfigurationProperty[];
  configurationSetChanges?: EnvironmentApi.ConfigurationSetChanges;
  ttl?: EnvironmentApi.TTLRequest;
  envName?: string;
  userRequiresApproval?: boolean;
  subEnvironments?: RunEnvironmentArgs['subEnvironments'];
  workflowDeploymentOptions?: EnvironmentApi.Deploy.Request.Body['workflowDeploymentOptions'];

  targets?: string[];
};

export default (client: HttpClient) => ({
  update: (environmentId: string, data: Partial<Environment>) => client.put(`environments/${environmentId}`, data),
  countActive: (projectId: string, status?: EnvironmentApi.EnvironmentStatus) => {
    const params: EnvironmentApi.CountByProject.Request.QueryParams = {
      projectId,
      status,
      isActive: 'true'
    };
    return client.get<number>(`environments/count`, { params: omitBy(params, isNil) });
  },
  getProjectsEnvironmentsStatusesCountsByOrganization: (
    params: EnvironmentApi.GetProjectsEnvsStatusesCountsByOrg.Request.QueryParams
  ) => {
    return client
      .get<EnvironmentApi.GetProjectsEnvsStatusesCountsByOrg.Response>(`environments/statuses/counts`, {
        params
      })
      .then(returnData);
  },
  getAll: ({
    organizationId,
    projectId,
    onlyMy,
    isActive,
    limit = DEFAULT_PAGE_SIZE_LIMIT,
    offset = 0,
    searchText
  }: {
    projectId?: string;
    organizationId?: string;
    onlyMy: boolean;
    isActive: boolean;
    limit?: number;
    offset?: number;
    searchText?: string;
  }) => {
    const params: EnvironmentApi.FindAll.Request.QueryParams = {
      projectId,
      onlyMy: onlyMy ? 'true' : 'false',
      isActive: isActive ? 'true' : 'false',
      limit: limit.toString(),
      offset: offset.toString(),
      organizationId,
      searchText
    };
    return client.get<Environment[]>('environments', {
      params
    });
  },
  create: (args: CreateEnvironmentArgs) => {
    const req: EnvironmentApi.CreateEnvironmentRequest = {
      ...args,
      configurationChanges: args.configurationChanges?.map(changedConfigurationToPayload)
    };

    return client.post<typeof req, AxiosResponse<Environment>>('environments', req);
  },
  deployEnvironmentWithoutTemplate: (args: DeployEnvironmentWithoutTemplateArgs) => {
    const req: EnvironmentApi.CreateEnvironmentWithoutTemplate.Request.Body = {
      ...args,
      configurationChanges: args.configurationChanges?.map(changedConfigurationToPayload)
    };

    return client.post<typeof req, AxiosResponse<Environment>>('environments/without-template', req);
  },
  deployToEnvironment: (args: DeployToEnvironmentArgs) => {
    const { environmentId, configurationChanges, ...otherArgs } = args;
    const req: EnvironmentApi.DeployToEnvironmentRequest = {
      ...otherArgs,
      configurationChanges: configurationChanges?.map(changedConfigurationToPayload)
    };

    return client.post<EnvironmentApi.DeployToEnvironmentRequest, AxiosResponse<DeploymentLog>>(
      `environments/${environmentId}/deployments`,
      req
    );
  },
  runTask: (environmentId: string, taskPayload: RunTaskPayload) => {
    return client
      .post<EnvironmentApi.DeployToEnvironmentRequest, AxiosResponse<DeploymentLog>>(
        `environments/${environmentId}/deployments`,
        {
          ...taskPayload,
          deploymentType: 'task'
        }
      )
      .then(returnData);
  },

  setEnvironmentTtl: (environmentId: string, ttl?: EnvironmentApi.TTLRequest) =>
    client
      .put<EnvironmentApi.TTLRequest, AxiosResponse<Environment>>(`environments/${environmentId}/ttl`, ttl)
      .then(returnData),

  saveAsTemplate: (environmentId: string, newName: string) =>
    client.put<Environment>(`environments/${environmentId}/template`, { newName }).then(returnData),

  getEnvironment: (id: string, config?: AxiosRequestConfig<Environment>) =>
    client
      .get<Environment>(`environments/${id}`, {
        ...config,
        params: { exclude_fields: 'latestDeploymentLog.plan,latestDeploymentLog.output,latestDeploymentLog.resources' }
      })
      .then(returnData),

  getEnvironmentDeployments: (
    environmentId: string,
    offset = 0,
    limit = DEFAULT_PAGE_SIZE_LIMIT,
    { fromDate, toDate, deploymentTypes }: DeploymentsFilterOptions = {}
  ): Promise<{ data: DeploymentLog[]; headers?: RawAxiosResponseHeaders & { 'x-has-more-pages'?: string } }> =>
    client
      .get<DeploymentLog[]>(`environments/${environmentId}/deployments`, {
        params: { limit, offset, fromDate, toDate, deploymentTypes: deploymentTypes?.join(',') }
      })
      .then(response => ({ data: response.data, headers: response.headers })),
  getPolicies: (projectId: string) =>
    client.get<EnvironmentApi.GetPolicies.Response>('policies', { params: { projectId } }).then(returnData),

  updatePolicies: (newPolicies: EnvironmentApi.UpdatePolicies.Request.Body) =>
    client
      .put<EnvironmentApi.UpdatePolicies.Request.Body, AxiosResponse<EnvironmentApi.UpdatePolicies.Response>>(
        'policies',
        newPolicies
      )
      .then(returnData),

  checkLimit: (projectId: string) =>
    client.get<EnvironmentApi.CheckLimit.Response>('policies/limits/check', { params: { projectId } }).then(returnData),

  findDownstreamEnvironments: (environmentId: string) =>
    client
      .get<EnvironmentApi.FindDownstreamEnvironments.Response>(`environments/${environmentId}/downstream`)
      .then(returnData),

  updateDownstreamEnvironments: (
    environmentId: string,
    updateDownstreamRequest: EnvironmentApi.UpdateDownstreamEnvironments.Request.Body
  ) =>
    client
      .put<
        EnvironmentApi.UpdateDownstreamEnvironments.Request.Body,
        AxiosResponse<EnvironmentApi.UpdateDownstreamEnvironments.Response>
      >(`environments/${environmentId}/downstream`, updateDownstreamRequest)
      .then(returnData),

  getDeploymentDataForCostChart: (environmentId: string, startedAfter: Date) =>
    client
      .get<EnvironmentApi.FindDeploymentLogsForCost.Response>(`environments/${environmentId}/deployments/cost`, {
        params: { startedAfter: startedAfter.toISOString() }
      })
      .then(returnData),

  updateEnvironmentLock: (environmentId: string, payload: EnvironmentApi.UpdateEnvironmentLock.Request.Body) =>
    client
      .put<EnvironmentApi.UpdateEnvironmentLock.Response>(`environments/${environmentId}/lock`, payload)
      .then(returnData),

  getUserRoleAssignments: (environmentId: string) =>
    client
      .get<RolesApi.UserRoleAssignment[]>('roles/assignments/users', { params: { environmentId } })
      .then(returnData),

  removeUserRoleAssignment: (environmentId: string, userId: string) =>
    client.delete('roles/assignments/users', { params: { environmentId, userId } }).then(returnData),

  assignRoleToUser: (environmentId: string, userId: string, role: RolesApi.RBACPermissionRole) =>
    client
      .put<RolesApi.UpsertUserRoleAssignment.Request.Body, AxiosResponse<RolesApi.UserRoleAssignment>>(
        `roles/assignments/users`,
        {
          userId,
          environmentId,
          role
        }
      )
      .then(returnData),
  moveEnvironment: (environmentId: string, projectId: string) =>
    client.post<EnvironmentApi.MoveEnvironment.Request.Body, AxiosResponse<EnvironmentApi.MoveEnvironment.Response>>(
      `environments/${environmentId}/move`,
      { projectId }
    )
});
